.add-about-wrapper {
  margin: 20px 30px 30px 0;

  h5 {
    font-size: 24px;
    font-weight: 400;
    color: #474747;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 450px;
  }

  .submit-btn,
  .add-sub-question-btn {
    background-color: #5a5abe;
    color: #fff;
    font-size: 15px;
    padding: 6px 30px;
    margin-top: 20px;
  }

  .view-btn {
    background-color: #5a5abe;
    color: #fff;
    font-size: 15px;
    padding: 6px 30px;
    float: right;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dropdown-selects {
  position: absolute !important;
  right: 8px;
  top: 6px;
}

.custom-option {
  font-size: 15px !important;
  height: 25px !important;
}

.thene-circle .lazy-load-image-background {
  margin: auto;
  display: block !important;
  margin-bottom: 10px;
}

.thene-circle .lazy-load-image-background img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.upload-image.input-width {
  width: 100%;
  font-size: 16px !important;
  padding: 10px 12px;
  border-radius: 6px;
}

.image-preview .lazy-load-image-background img {
  margin: auto !important;
  display: block !important;
}

.export-btn {
  background: linear-gradient(
    271.86deg,
    #8484df 0.13%,
    #5a5abe 97.84%
  ) !important;
  box-shadow: 0px 23px 24px -10px rgb(156 101 73 / 37%) !important;
  border-radius: 5px !important;
  padding: 5px 20px !important;
  color: white !important;
  border: 1px solid #696bcc !important;
  font-size: 16px !important;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1) !important;
}

.export-btn:hover {
  background: #5a5abe !important;
  border: 1px solid #696bcc !important;
  transform: translateY(-2px) !important;
}

.manage-patient-wrapper.m-pw .export-btn .fa {
  color: white !important;
}

.upload-image.input-width.d-inline {
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1) !important;
}

.upload-image.input-width.d-inline:hover {
  background-color: white !important;
  color: #696bcc !important;
  transform: translateY(-2px) !important;
}

.manage-patient-wrapper .loader {
  position: absolute;
  left: 50%;
  top: 20vh;
  color: black;
}

.manage-patient-wrapper svg {
  position: absolute;
  /* top: 45vh; */
}

.filter-form {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  height: 50px;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;
}

.filter-size {
  font-size: 22px;
}

.filter-select {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.filter-select:focus-visible {
  outline: none;
}

.remove-filter-icon {
  font-size: 20px;
  margin-left: 4px;
  cursor: pointer;
}

table {
  text-align: center;
}

.image-size div span img {
  width: 90px;
  height: 120px;
  object-fit: cover;
}

/* .dashboardTable {
  text-align: inherit !important;
} */

.dashboard.new-dashboard .dashboard-color-4.least-background i {
  background-color: #ff8989 !important;
  color: #862b0d;
}

.dashboard.new-dashboard .dashboard-color-1.least-category i {
  background-color: #c5dff8 !important;
  color: #068fff;
}

.dashboard.new-dashboard .dashboard-color-4.shared-affirmation i {
  background-color: #cceebc !important;
  color: #1a5d1a;
}

.add-patient-btn-new {
  background: #696bcc !important;
  color: white !important;
  border-radius: 8px !important;
  padding: 10px 25px !important;
  border: 1px solid #696bcc !important;
}

.add-patient-btn-new:hover {
  background: white !important;
  border-radius: 8px !important;
  padding: 10px 25px !important;
  color: #696bcc !important;
  border: 1px solid #696bcc !important;
}

.favourites div .lazy-load-image-background img {
  width: 120px;
  height: 150px;
}

.favourited {
  color: red;
}

.admin-forgot-password {
  background: #184465;
  padding-top: 200px;
  height: 100vh;
  max-height: 100%;
  overflow-y: auto;

  h5 {
    padding-bottom: 15px;
    text-align: left;
  }

  .forgot-password-btn {
    background-color: #39aaff;
    color: #fff;
    font-size: 14px;
    padding: 8px 20px 9px 20px;
    border: none;
  }

  .fa {
    color: #39aaff;
  }

  a {
    color: #474747;
    text-decoration: none;
    font-size: 14px;
  }
}

.dashboard {
  padding: 0px 20px 40px 0px;

  h3 {
    padding-top: 30px;
  }

  hr {
    margin-bottom: 0;
  }

  .statistics {
    padding: 20px 10px;
    color: #fff;
    border-radius: 3px;
    margin-top: 20px;

    h5 {
      font-size: 23px !important;
      color: #fff;
    }

    p {
      font-size: 15px;
      margin-bottom: 0 !important;
    }
  }

  .line-chart {
    margin-top: 30px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px 30px 10px 0;
    border-radius: 3px;
    height: auto;
    font-size: 15px;

    h6 {
      padding-left: 30px;
    }

    .recharts-wrapper {
      width: 100% !important;
    }

    .recharts-surface {
      width: 100% !important;
    }
  }

  .bar-chart {
    margin-top: 30px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px 30px 10px 0px;
    border-radius: 3px;
    height: auto;
    font-size: 15px;

    h6 {
      padding-left: 30px;
    }

    .recharts-wrapper {
      width: 100% !important;
    }

    .recharts-surface {
      width: 100% !important;
    }
  }

  .recent-appointments {
    margin-top: 30px;
    margin-right: 15px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px 30px 10px 30px;
    border-radius: 3px;

    h6 {
      padding-bottom: 20px;
    }

    th,
    td {
      font-size: 15px;
    }

    a {
      text-decoration: none;
      color: #474747;
    }

    .cancelled {
      color: #fff;
      background-color: tomato;
      padding: 5px 15px 5px 15px;
      border-radius: 2px;
      font-size: 12px;
    }
  }
}

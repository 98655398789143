.profile-wrapper {
  margin: 40px 30px 40px 0;

  h5 {
    font-size: 24px;
    font-weight: 400;
    color: #474747;
  }

  .profile-img {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    padding: 30px;
    text-align: center;
    margin-top: 10px;

    img {
      height: 250px;
      width: 250px;
      border-radius: 50%;
    }

    h4 {
      font-size: 18px;
      font-weight: 400;
      padding-top: 20px;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      padding-top: 10px;
      margin-bottom: 0;
    }

    input[type="file"] {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }

    label {
      border: 1px solid #5a5abe;
      color: #5a5abe;
      background-color: transparent;
      padding: 5px 12px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 400;
      margin-top: 25px;
      transition: 0.4s;
    }

    label:hover {
      cursor: pointer;
      background-color: #5a5abe;
      color: #fff;
    }
  }

  .profile-details {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    padding: 20px;
    margin-top: 10px;

    h4 {
      font-size: 17px;
      font-weight: 400;
      padding-bottom: 10px;
    }

    i {
      color: #5a5abe;
    }

    .update-profile-btn {
      background-color: #5a5abe;
      color: #fff;
      font-size: 14px;
      border-color: #5a5abe;
      padding: 8px 0;
    }
  }
}

.policy {
  padding: 40px;

  h4 {
    font-size: 24px;
    font-weight: 400;
    color: #474747;
  }

  hr {
    margin-bottom: 0;
  }
}

/* Login Screen */

.admin-login.new-admin-login {
  background-image: linear-gradient(to top, #8484df 0%, #5a5abe 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  /* height: 100vh; */
}

.new-admin-login .card {
  padding: 50px 20px;
  box-shadow: rgba(0, 0, 219, 0.2) 0px 7px 29px 0px;
}

.admin-login.new-admin-login h5 {
  font-weight: 600;
  font-size: 18px;
  color: #424242;
  margin: 30px 0px 10px;
  position: relative;
  text-align: center;
}

/* .admin-login.new-admin-login h5::after{
		content: '';
		position: absolute;
		left: 10%;
		bottom: 0;
		transform: translate(-50%,-50%);
		width: 20%;
		height: 3px;
		background-color: black; 
	} */

.admin-login.new-admin-login .fa {
  color: #696bcc;
  top: 7px;
}

.admin-login.new-admin-login .form-group {
  position: relative;
}

.admin-login.new-admin-login .form-group input {
  height: 50px;
  border-radius: 8px;
}

.admin-login.new-admin-login .form-group input:focus {
  border-color: #696bcc;
}

.admin-login.new-admin-login .login-btn {
  background-color: #696bcc;
  color: #fff;
  font-size: 15px;
  padding: 0px;
  border: 1px solid #696bcc;
  font-weight: bold;
  letter-spacing: 1px;
}

.admin-login.new-admin-login .login-btn:hover {
  border: 1px solid #696bcc !important;
  background-color: white;
  color: #696bcc;
  font-size: 15px;
  padding: 0px;
  border: none;
  font-weight: bold;
  letter-spacing: 1px;
}

.admin-login.new-admin-login .new-forgot a {
  font-weight: bold;
  color: #5a5abe;
}

.admin-login.new-admin-login .new-forgot a:hover {
  color: #424242;
}

/* Forgot Password Screen */

.admin-forgot-password.ad-for-psd {
  background-image: linear-gradient(to top, #8484df 0%, #5a5abe 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
}

.ad-for-psd .card {
  padding: 50px 20px;
  box-shadow: rgba(0, 0, 219, 0.2) 0px 7px 29px 0px;
}

.admin-forgot-password.ad-for-psd h5 {
  font-weight: 600;
  font-size: 18px;
  color: rgb(66, 66, 66);
  margin: 30px 0px 10px;
  position: relative;
}

.admin-forgot-password.ad-for-psd .fa {
  color: #696bcc;
  top: 7px;
}

.admin-forgot-password.ad-for-psd .form-group {
  position: relative;
}

.admin-forgot-password.ad-for-psd .form-group input {
  height: 50px;
  border-radius: 8px;
}

.admin-forgot-password.ad-for-psd .form-group input:focus {
  border-color: #696bcc;
}

.admin-forgot-password.ad-for-psd .forgot-password-btn {
  background-color: #696bcc;
  color: #fff;
  font-size: 15px;
  padding: 0px;
  border: 1px solid #696bcc;
  font-weight: bold;
  letter-spacing: 1px;
}

.admin-forgot-password.ad-for-psd .forgot-password-btn:hover {
  border: 1px solid #696bcc !important;
  background-color: white;
  color: #696bcc;
  font-size: 15px;
  padding: 0px;
  border: none;
  font-weight: bold;
  letter-spacing: 1px;
}

.admin-forgot-password.ad-for-psd .backTo {
  font-weight: bold;
  color: #5a5abe;
}

.admin-forgot-password.ad-for-psd .backTo:hover {
  color: #424242;
}

.admin-forgot-password.ad-for-psd .backTo:hover i {
  color: #424242;
}

/* Settings */

.new-page-heading {
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #424242;
}

.settings.new-sett .change-password {
  margin-top: 0px;
  padding: 50px 40px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.settings.new-sett .change-password h4 {
  font-weight: 600;
  font-size: 16px;
  color: #727272;
}

.settings.new-sett .change-password .fa {
  color: #696bcc;
  top: 7px;
}

.settings.new-sett .change-password .form-group {
  position: relative;
}

.settings.new-sett .change-password .form-group input {
  height: 50px;
  border-radius: 8px;
  margin-bottom: 25px;
}

.settings.new-sett .change-password .form-group input:focus {
  border-color: #696bcc;
}

.settings.new-sett .change-password .change-pass-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.settings.new-sett .change-password .change-pass-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 16px;
}

/* Profile */

.profile-wrapper.new-pw .new-page-heading {
  font-size: 22px !important;
  font-weight: 600;
  color: #424242;
  padding: 0px 0px 15px;
}
.profile-wrapper.new-pw {
  margin: 30px 30px 40px 0;
}

.profile-wrapper.new-pw .profile-img {
  margin-top: 0px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  text-align: inherit;
}

.profile-wrapper.new-pw
  .lazy-load-image-background.blur.lazy-load-image-loaded {
  width: inherit;
  display: inherit !important;
  text-align: center;
}

.profile-wrapper.new-pw .profile-img label {
  border: 1px solid #5a5abe;
  color: #5a5abe;
  background-color: transparent;
  padding: 12px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 800;
  margin-top: 15px;
  transition: 0.4s;
}

.profile-wrapper.new-pw .profile-img h4 {
  font-size: 22px;
  font-weight: bold;
  padding-top: 20px;
  color: #5a5abe;
}

.profile-wrapper.new-pw .profile-img p i {
  width: 20px;
  height: 20px;
  font-size: 15px;
  font-weight: bold;
}

.profile-wrapper.new-pw .profile-img p {
  color: #000;
}

.profile-wrapper.new-pw .profile-details {
  background-color: #fff;
  margin-top: 0px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 50px 30px;
}

.profile-wrapper.new-pw .profile-details h4 {
  font-weight: 600;
  font-size: 16px;
  color: #727272;
}

.profile-wrapper.new-pw .profile-details .fa {
  color: #696bcc;
  top: 7px;
}

.profile-wrapper.new-pw .profile-details .form-group {
  position: relative;
}

.profile-wrapper.new-pw .profile-details .form-group input {
  height: 50px;
  border-radius: 8px;
  margin-bottom: 25px;
}

.profile-wrapper.new-pw .profile-details .form-group input:focus {
  border-color: #696bcc;
}

.profile-wrapper.new-pw .profile-details .update-profile-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.profile-wrapper.new-pw .profile-details .update-profile-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 16px;
}

/* Static Content Privacy Policy & Terms & Conditions  */

.add-about-wrapper.new-aaw .new-page-heading {
  font-size: 22px !important;
  font-weight: 600;
  color: #424242;
}

.add-about-wrapper.new-aaw {
  margin: 30px 30px 40px 0;
}

.add-about-wrapper.new-aaw .view-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 5px;
  padding: 6px 16px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 15px;
}

.add-about-wrapper.new-aaw .view-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 5px;
  padding: 6px 16px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 15px;
}

.add-about-wrapper.new-aaw form .ck-editor {
  margin-top: 0px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.add-about-wrapper.new-aaw form .ck-editor .ck-content:focus {
  border: 1px solid #696bcc !important;
}

.add-about-wrapper.new-aaw .submit-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.add-about-wrapper.new-aaw .submit-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 16px;
}

/*  FAQ  */

.add-faq-wrapper.new-faq .new-page-heading {
  font-size: 22px !important;
  font-weight: bold;
  color: #424242;
}

.add-faq-wrapper.new-faq {
  margin: 30px 30px 40px 0;
}

.add-faq-wrapper.new-faq form .ck-editor {
  margin-top: 0px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.add-faq-wrapper.new-faq form .ck-editor .ck-content:focus {
  border: 1px solid #696bcc !important;
}

.add-faq-wrapper.new-faq .fa {
  color: #696bcc;
  top: 7px;
}

.add-faq-wrapper.new-faq .form-group {
  position: relative;
}

.add-faq-wrapper.new-faq .form-group input {
  height: 50px;
  border-radius: 8px;
  margin-bottom: 25px;
}

.add-faq-wrapper.new-faq .form-group input:focus {
  border-color: #696bcc;
}

.add-faq-wrapper.new-faq label.status-label {
  font-weight: bold;
  font-size: 16px;
  color: #727272;
}

.add-faq-wrapper.new-faq
  .pretty
  input:checked
  ~ .state.p-primary-o
  label:before {
  border-color: #696bcc;
}

.add-faq-wrapper.new-faq
  .pretty.p-default:not(.p-fill)
  input:checked
  ~ .state.p-primary-o
  label:after {
  background-color: #696bcc !important;
}

.add-faq-wrapper.new-faq .pretty .state label {
  font-weight: 600;
}
.add-faq-wrapper.new-faq .submit-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.add-faq-wrapper.new-faq .submit-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 16px;
}

/* Manage Faq */

.manage-faq-wrapper.new-mfaq .new-page-heading {
  font-size: 22px !important;
  font-weight: 600;
  color: #424242;
  margin-bottom: 22px;
}
.manage-faq-wrapper.new-mfaq .manage-faq {
  margin: 30px 0px 0px 0;
  background-color: #fff;
  margin-top: 0px;
  padding: 30px 10px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.manage-faq-wrapper.new-mfaq table thead {
  background-color: #e8e8ff;
}

.manage-faq-wrapper.new-mfaq table > tbody > tr:nth-of-type(odd) > * {
  background: white !important;
  opacity: 1 !important;
  display: table-cell !important;
}

.manage-faq-wrapper.new-mfaq table > tbody > tr:nth-of-type(even) > * {
  background: #f7f7f7;
  display: table-cell !important;
}

.manage-faq-wrapper.new-mfaq .manage-faq thead tr th {
  color: black;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
}

.manage-faq-wrapper.new-mfaq .manage-faq table tbody tr td ol {
  padding: 0px 12px !important;
}

.manage-faq-wrapper.new-mfaq .fa {
  color: #696bcc !important;
  top: 7px;
}

.manage-faq-wrapper.new-mfaq .form-group {
  position: relative;
}

.manage-faq-wrapper.new-mfaq .form-group input {
  height: 50px;
  border-radius: 8px;
  margin-bottom: 25px;
}

.manage-faq-wrapper.new-mfaq .form-group input:focus {
  border-color: #696bcc;
}

.manage-faq-wrapper.new-mfaq table .Edit-Button {
  background-color: #e7e7ff;
  border-radius: 8px;
  padding: 10px !important;
  display: block;
  margin-bottom: 10px;
}
.manage-faq-wrapper.new-mfaq table .Edit-Button i {
  color: #5a5abe !important;
  text-align: center;
  width: 18px;
  height: 18px;
  padding-right: 0px;
}

.manage-faq-wrapper.new-mfaq table .Delete-Button {
  background-color: rgb(255 231 231);
  border-radius: 8px;
  padding: 10px !important;
  display: block;
}
.manage-faq-wrapper.new-mfaq table .Delete-Button i {
  color: red !important;
  text-align: center;
  padding-right: 0px;
  width: 18px;
  height: 18px;
}

/* Update Edit FAQ Manage Modal */

.Update_FAQ .modal-title.h4 {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #424242;
}

.Update_FAQ form .ck-editor {
  margin-top: 0px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.Update_FAQ form .ck-editor .ck-content:focus {
  border: 1px solid #696bcc !important;
}

.Update_FAQ .fa {
  color: #696bcc;
  top: 7px;
}

.Update_FAQ .form-group {
  position: relative;
}

.Update_FAQ .form-group input {
  height: 50px;
  border-radius: 8px;
  margin-bottom: 25px;
}

.Update_FAQ .form-group input:focus {
  border-color: #696bcc;
}

.Update_FAQ label.status-label {
  font-weight: bold;
  font-size: 16px;
  color: #727272;
}

.Update_FAQ .pretty input:checked ~ .state.p-primary-o label:before {
  border-color: #696bcc;
}

.Update_FAQ
  .pretty.p-default:not(.p-fill)
  input:checked
  ~ .state.p-primary-o
  label:after {
  background-color: #696bcc !important;
}

.Update_FAQ .pretty .state label {
  font-weight: 600;
}
.Update_FAQ .add-doctor-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.Update_FAQ .add-doctor-btn :hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 16px;
}

/* Manage Themes & Fonts */

.manage-patient-wrapper.m-pw .new-page-heading {
  font-size: 22px !important;
  font-weight: bold;
  color: #424242;
  margin-bottom: 22px;
}
.manage-patient-wrapper.m-pw .manage-patient {
  margin: 30px 0px 0px 0;
  background-color: #fff;
  margin-top: 0px;
  padding: 30px 10px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.manage-patient-wrapper.m-pw .manage-patient .add-new-patient-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 5px;
  padding: 6px 16px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 15px;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.manage-patient-wrapper.m-pw .manage-patient .add-new-patient-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 5px;
  padding: 6px 16px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 15px;
  transform: translateY(-2px);
}

.manage-patient-wrapper.m-pw table thead {
  background-color: #e8e8ff;
}

.manage-patient-wrapper.m-pw table > tbody > tr:nth-of-type(odd) > * {
  background: white !important;
  opacity: 1 !important;
}

.manage-patient-wrapper.m-pw table > tbody > tr:nth-of-type(even) > * {
  background: #f7f7f7;
}

.manage-patient-wrapper.m-pw .manage-patient thead tr th {
  color: black;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
}

.manage-patient-wrapper.m-pw .manage-patient table tbody tr td ol {
  padding: 0px 12px !important;
}

.manage-patient-wrapper.m-pw .fa {
  color: #696bcc !important;
  top: 7px;
}

.manage-patient-wrapper.m-pw .form-group {
  position: relative;
}

.manage-patient-wrapper.m-pw .form-group input {
  height: 50px;
  border-radius: 8px;
  margin-bottom: 25px;
}

.manage-patient-wrapper.m-pw .form-group input:focus {
  border-color: #696bcc;
}

.manage-patient-wrapper.m-pw table .Edit-Button {
  background-color: #e7e7ff;
  border-radius: 5px;
  padding: 10px !important;
  margin-right: 10px;
}
.manage-patient-wrapper.m-pw table .Edit-Button i {
  color: #5a5abe !important;
  text-align: center;
  padding-right: 0px;
  width: 18px;
  height: 18px;
}

.manage-patient-wrapper.m-pw table .Delete-Button {
  background-color: rgb(255 231 231);
  border-radius: 5px;
  padding: 10px !important;
}
.manage-patient-wrapper.m-pw table .Delete-Button i {
  color: red !important;
  text-align: center;
  padding-right: 0px;
  width: 18px;
  height: 18px;
}

.manage-patient-wrapper.m-pw table .active {
  background-color: rgba(0, 187, 74, 0.24);
  box-shadow: none;
  color: green;
  font-weight: bold;
}

.manage-patient-wrapper.m-pw table .inactive {
  background-color: rgba(202, 11, 11, 0.24) !important;
  box-shadow: none;
  color: rgba(199, 31, 31, 0.863) !important;
  font-weight: bold;
  height: 18px;
  padding: 5px 6px 5px 6px;
  font-size: 12px;
  border-radius: 2px;
}

.table .inactive {
  background-color: red !important;
  box-shadow: none !important;
  color: #ffffff;
  font-weight: bold !important;
  height: 18px !important;
  padding: 5px 6px 5px 6px !important;
  font-size: 12px !important;
  border-radius: 2px !important;
}

/* Manage Themes and Fonts Add Theme Modal */

.Update_Addtheme .modal-title.h4 {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #424242;
}

.Update_Addtheme .fa {
  color: #696bcc;
  top: 7px;
}

.Update_Addtheme .form-group {
  position: relative;
}

.Update_Addtheme .form-group input {
  height: 50px;
  border-radius: 8px;
  margin-bottom: 25px;
}

.Update_Addtheme .form-group input:focus {
  border-color: #696bcc;
}

.Update_Addtheme .add-patient-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.Update_Addtheme .add-patient-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 16px;
}

/* Theme Details */

.patient-details.new-pd .new-page-heading {
  font-size: 22px !important;
  font-weight: bold;
  color: #424242;
  padding: 0px 0px 15px;
}

.patient-details.new-pd {
  margin: 30px 30px 40px 0;
}

.patient-details.new-pd .basic-details {
  margin-top: 0px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.patient-details.new-pd .personal-details {
  background-color: #fff;
  margin-top: 0px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 50px 30px;
}

.patient-details.new-pd .personal-details h5 {
  font-weight: bold;
  font-size: 16px;
  color: #727272;
}

.patient-details.new-pd .personal-details .fa {
  color: #696bcc;
  font-size: inherit !important;
  top: 7px;
}

.patient-details.new-pd .personal-details .form-group {
  position: relative;
}

.patient-details.new-pd .personal-details .form-group input {
  height: 50px;
  border-radius: 8px;
}

.patient-details.new-pd .personal-details .form-group input:focus {
  border-color: #696bcc;
}

.patient-details.new-pd .personal-details .update-details-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.patient-details.new-pd .personal-details .update-details-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 16px;
}

/* Same Css As Manage Themes & Fonts , Manage Text Contents , Sub Categories Page  */

/* Manage Sub Categories Add Modal */

.AddSubcategory-Modal .modal-title.h4 {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #424242;
}

.AddSubcategory-Modal .fa {
  color: #696bcc;
  top: 7px;
}

.AddSubcategory-Modal .form-group {
  position: relative;
}

.AddSubcategory-Modal .form-group input {
  height: 50px;
  border-radius: 8px;
}

.AddSubcategory-Modal .form-group input:focus {
  border-color: #696bcc;
}

.AddSubcategory-Modal .add-patient-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.AddSubcategory-Modal .add-patient-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.AddSubcategory-Modal .upload-image {
  border: 1px solid #5a5abe;
  color: #5a5abe;
  background-color: transparent;
  padding: 12px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 800;
  margin-top: 15px;
  transition: 0.4s;
}

.AddSubcategory-Modal label.status-label {
  font-weight: bold;
  font-size: 16px;
  color: #727272;
}

.AddSubcategory-Modal .pretty input:checked ~ .state.p-primary-o label:before {
  border-color: #696bcc;
}

.AddSubcategory-Modal
  .pretty.p-default:not(.p-fill)
  input:checked
  ~ .state.p-primary-o
  label:after {
  background-color: #696bcc !important;
}

.AddSubcategory-Modal .pretty .state label {
  font-weight: 600;
}

.table td {
  border-top: 0px;
}

.manage-patient-wrapper.m-pw table .btn-sucess {
  background-color: rgba(0, 187, 74, 0.24);
  box-shadow: none;
  color: green;
  font-weight: bold;
  border: none;
  padding: 4px 10px 5px 10px;
}

.manage-patient-wrapper.m-pw table textarea {
  border: 1px solid #ced4da;
}

.manage-patient-wrapper.m-pw table textarea:focus-visible {
  border: 1px solid #696bcc !important;
  box-shadow: none !important;
  outline: none !important;
}

/* Subcategory Details */

.patient-details.new-pd .ratings h5 {
  font-weight: bold;
  font-size: 16px;
  color: #727272;
}

.patient-details.new-pd .ratings {
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Manage Affirmation  Add Modal */

.AddAffirmation-Modal .modal-title.h4 {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #424242;
}

.AddAffirmation-Modal .fa {
  color: #696bcc;
  top: 7px;
}

.AddAffirmation-Modal .form-group {
  position: relative;
}

.AddAffirmation-Modal .form-group input {
  height: 50px;
  border-radius: 8px;
}

.AddAffirmation-Modal .form-group input:focus {
  border-color: #696bcc;
}

.AddAffirmation-Modal .add-patient-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.AddAffirmation-Modal .add-patient-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 16px;
}

/* Manage Preference Add Modal and Manage Categories Modal and Customer and Voice  Same */

.AddPreference-Modal .modal-title.h4 {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #424242;
}

.AddPreference-Modal .fa {
  color: #696bcc;
  top: 7px;
}

.AddPreference-Modal .form-group {
  position: relative;
}

.AddPreference-Modal .form-group input {
  height: 50px;
  border-radius: 8px;
}

.AddPreference-Modal .form-group input:focus {
  border-color: #696bcc;
}

.AddPreference-Modal .add-patient-btn {
  background: linear-gradient(271.86deg, #8484df 0.13%, #5a5abe 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: white;
  border: 1px solid #696bcc;
  font-size: 16px;
}

.AddPreference-Modal .add-patient-btn:hover {
  background: white;
  box-shadow: 0px 23px 24px -10px rgba(156, 101, 73, 0.37);
  border-radius: 8px;
  padding: 10px 25px;
  color: #696bcc;
  border: 1px solid #696bcc;
  font-size: 16px;
}

/* Dashboard */

.dashboard.new-dashboard .statistics {
  /* background-image: linear-gradient(to top, #8484df 0%, #5a5abe 100%); */
  background-color: #fff !important;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.dashboard.new-dashboard .statistics h5 {
  color: #5a5abe !important;
  font-weight: bold;
}

.dashboard.new-dashboard .statistics p {
  color: black !important;
}

.dashboard.new-dashboard .statistics .icon_col .icon {
  width: 50px !important;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  margin: 0px !important;
  background-image: linear-gradient(to top, #8484df 0%, #5a5abe 100%);
  border-radius: 8px;
}

.dashboard.new-dashboard .statistics .icon_col .icon i {
  color: rgb(255, 255, 255) !important;
  font-size: 25px;
}

.navbar .fa-bars {
  font-size: 20px;
  /* background-color: #e7e7ff; */
  background: rgba(105, 107, 204, 0.1);
  padding: 12px 15px;
  border-radius: 8px;
  color: #5a5abe;
}

.dashboard.new-dashboard .card.new-c {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dashboard.new-dashboard table thead {
  background-color: #e8e8ff;
}

.dashboard.new-dashboard table > tbody > tr:nth-of-type(odd) > * {
  opacity: 1 !important;
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
}

.dashboard.new-dashboard table > tbody > tr:nth-of-type(even) > * {
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
}

.dashboard.new-dashboard .dashboard-color-1 i {
  background: #ffe9b3;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dfa000;
  font-size: 30px;
  border-radius: 8px;
}

.dashboard.new-dashboard .dashboard-color-2 i {
  background: rgba(0, 193, 160, 0.15);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00c1a0;
  font-size: 30px;
  border-radius: 8px;
}

.dashboard.new-dashboard .dashboard-color-3 i {
  background: rgba(0, 187, 74, 0.15);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #00bb4a;
  border-radius: 8px;
}

.dashboard.new-dashboard .dashboard-color-4 i {
  background: rgba(254, 124, 30, 0.15);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fe7c1e;
  border-radius: 8px;
}

.dashboard.new-dashboard table tr .one_board {
  color: #dfa000;
  font-weight: bold;
}

.dashboard.new-dashboard table tr .shared_board {
  color: #00bb4a;
  font-weight: bold;
}

.dashboard.new-dashboard table tr .category_board {
  color: #00c1a0;
  font-weight: bold;
}

.dashboard.new-dashboard table tr .shared_board {
  color: #00bb4a;
  font-weight: bold;
}

.dashboard.new-dashboard table tr .two_board {
  color: #00c1a0;
  font-weight: bold;
}

.dashboard.new-dashboard table tr .three_board {
  color: #00bb4a;
  font-weight: bold;
}

.dashboard.new-dashboard table tr .four_board {
  color: #fe7c1e;
  font-weight: bold;
}

::-webkit-scrollbar-thumb {
  background: #3e3e85 !important;
}

::-webkit-scrollbar-track {
  background: #dddddd;
  border: 0 #b5b5b5;
  border-radius: 50px;
}

.navbar #navbar-user-img {
  border-radius: 50%;
  padding: 2px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.navbar .nav-item .dropdown a {
  font-weight: 600;
  color: #424242;
  font-size: 15px;
}

/* Fu */

.pro-sidebar .logo {
  width: 100px;
}

.pro-sidebar {
  width: 285px !important;
}

.navbar .navbar-nav li:nth-child(3) {
  padding-left: 1px !important;
  padding-top: 1px;
}

.pro-sidebar .pro-menu-item {
  padding: 2px 0px;
}

.pro-menu-item {
  margin: 0 3px;
}

.pro-sidebar .pro-menu-item.active {
  background-color: white !important;
  border-radius: 8px !important;
}

.pro-sidebar .pro-menu-item.active .pro-inner-item i {
  color: #5a5abe !important;
}

.pro-sidebar .pro-menu-item.active .pro-inner-item .pro-item-content {
  color: #5a5abe !important;
  font-weight: bold;
}

.navbar .user-shortcut-controls {
  left: -75px !important;
}

.navbar .user-shortcut-controls a {
  font-weight: 400 !important;
}

body {
  background: rgba(105, 107, 204, 0.02);
}

/* .pro-sidebar .pro-menu-item .pro-inner-item i{
  color: #424242 !important;
}
.pro-sidebar .pro-menu-item .pro-inner-item .pro-item-content {
  color: #424242 !important;
} 
 .pro-sidebar .pro-sidebar-content{
	background:  white !important;
}
.pro-sidebar .pro-sidebar-layout{
	background-color: white !important;
}
.navbar .toggle-sidebar-menu{
	left: 310px !important;
}
.pro-sidebar .pro-sidebar-content{
	background:  white !important;
}
.pro-sidebar .pro-sidebar-layout{
	background-color: white !important;
}
.pro-sidebar .pro-menu-item .pro-inner-item .pro-item-content {
  color: #424242 !important;
}
.pro-sidebar .pro-menu-item .pro-inner-item i {
  color: #424242 !important;
}
.pro-sidebar .pro-menu-item{
	padding:3px 0px;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  border-color: #424242 !important;
}
.main{
  background: #e5e5ff;
} 
*/

.admin-reset-password.new {
  background-image: linear-gradient(to top, #8484df 0%, #5a5abe 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  /* height: 100vh; */
}

.admin-reset-password.new h5 {
  font-weight: 600;
  font-size: 18px;
  color: #424242;
  margin: 30px 0 10px;
  position: relative;
  text-align: center;
}

.admin-reset-password.new .form-group input {
  height: 50px;
  border-radius: 8px;
}

.admin-reset-password.new .form-group {
  position: relative;
}

.admin-reset-password.new .fa {
  color: #696bcc;
  top: 7px;
}
.admin-reset-password.new .card {
  padding: 50px 20px;
  box-shadow: 0 7px 29px 0 rgb(0 0 219 / 20%);
}

.admin-reset-password.new .reset-password-btn {
  background-color: #696bcc;
  color: #fff;
  font-size: 15px;
  padding: 0;
  border: 1px solid #696bcc;
  font-weight: 700;
  letter-spacing: 1px;
}

.admin-reset-password.new .backTo a {
  font-weight: 700;
  color: #5a5abe;
}

.select_option {
  height: 50px;
  border-radius: 8px;
  background: none;
  border: 1px solid #ced4da;
  font-size: 15px;
  color: #495057;
}

.select_option:focus {
  border-color: #696bcc;
  box-shadow: none;
  outline: none;
}

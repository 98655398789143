.add-faq-wrapper {
  margin: 40px 30px 40px 0;

  h5 {
    font-size: 24px;
    font-weight: 400;
    color: #474747;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 350px;
  }

  .react-tagsinput {
    padding-left: 30px;
  }

  .react-tagsinput--focused {
    border-color: #39aaff;
  }

  .react-tagsinput-input {
    font-size: 14px;
  }

  .react-tagsinput-tag {
    background-color: #39aaff;
    border-color: #39aaff;
    color: #fff;
  }

  .react-tagsinput-remove {
    color: #fff;
  }

  .submit-btn,
  .add-sub-question-btn {
    background-color: #39aaff;
    color: #fff;
    font-size: 15px;
    padding: 6px 20px;
    margin-top: 20px;
  }

  .add-sub-question-btn {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .remove-btn {
    background: transparent;
    border-color: #474747;
    color: #474747;
    font-size: 13px;
    padding: 4px 15px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.manage-faq-wrapper {
  margin: 30px 30px 30px 0;

  h5 {
    font-size: 22px;
    font-weight: 400;
    color: #474747;
  }

  .manage-faq {
    margin: 25px 0px 0px 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px 45px;
    border-radius: 3px;

    th,
    td {
      font-size: 14px;
      color: #474747;
    }

    .icon {
      color: #39aaff;
    }

    img {
      width: 40px;
      border-radius: 50%;
    }

    a i {
      text-decoration: none;
      color: #474747;
      font-size: 16px;
      padding-right: 12px;
    }

    .Active,
    .Inactive {
      background-color: green;
      color: #fff;
      padding: 5px 15px;
      font-size: 12px;
    }

    .Inactive {
      background-color: tomato;
    }

    h6 {
      font-weight: 400 !important;
    }
  }
}

.navbar {
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  height: 70px;
  justify-content: flex-end !important;

  .toggle-sidebar-menu {
    cursor: pointer;
    position: absolute;
  }

  .navbar-nav {
    flex-direction: row;

    li {
      padding-left: 50px;

      &:nth-child(3) {
        padding-left: 10px;
        padding-top: 1px;
      }

      .badge {
        background-color: #39aaff;
      }
    }
  }

  .dropdown-menu-notifications {
    position: absolute !important;
    top: 55px;
    left: -346px;
    width: 400px;
    padding: 10px 20px;

    .dropdown-item {
      &:active {
        background-color: #fff !important;
      }
    }

    #notification-img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    .notification-text {
      white-space: initial;
    }

    .notification-time {
      color: #474747;
      font-size: 12px;
      font-weight: 400;
      margin: 0;
      padding-top: 5px;
    }

    p {
      color: #474747;
      font-size: 15px;
      font-weight: 400;
      margin: 0;

      a {
        color: #474747;
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        float: right;
      }
    }
  }

  .user-shortcut-controls {
    position: absolute !important;
    top: 55px;
    left: -50px;

    .dropdown-item {
      font-size: 14px;
      font-weight: 400;
      color: #474747;

      &:active {
        background-color: #fff !important;
      }
    }
  }

  #navbar-user-img {
    display: flex;
    justify-content: flex-end;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
